import logii from "./image/bcllc.png";
import logiiaws from "./image/nb.png";
import amazonappstore from "./image/amazon-store.png";
import footeraws from "./image/footeraws.png";
import appstorebadge from "./image/app-store.png";
import googleplaybadge from "./image/play-store.png";
import star from "./image/star.png";
import "./App.css";
import React, { useState, useEffect } from "react";
import { AppointmentPicker } from "react-appointment-picker";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import "bootstrap/dist/css/bootstrap.min.css";
import Image from "react-bootstrap/Image";
import { MdOutlineAccessTime } from "react-icons/md";
import { MdOutlineVideocam } from "react-icons/md";
import { MdOutlineArrowBackIos } from "react-icons/md";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import BeatLoader from "react-spinners/BeatLoader";
import toast from "react-hot-toast";
import TagManager from "react-gtm-module";
import moment from "moment";

const tagManagerArgs = {
  gtmId: "GTM-WHBB8R4",
};

TagManager.initialize(tagManagerArgs);

// function generateDateJson(N) {
//   const result = {};
//   const currentDate = new Date(); // Get the current date
//   const currentHour = currentDate.getHours();
//   const currentMinutes = currentDate.getMinutes(); // Get the current minutes

//   const currentTimestamp = new Date();
//   for (let i = 0; i < N; i++) {
//     const newDate = new Date(
//       currentDate.getFullYear(),
//       currentDate.getMonth(),
//       currentDate.getDate() + i,
//       currentTimestamp.getHours(), // Set current hour
//       currentTimestamp.getMinutes()
//     );
//     console.log(
//       newDate,
//       currentDate.getDate() + i,
//       currentDate.getDate(),
//       "tt"
//     );
//     const dateString = newDate.toISOString().split("T")[0]; // Format as YYYY-MM-DD
//     const isWeekend = newDate.getDay() === 0 || newDate.getDay() === 6;
//     result[dateString] = [];

//     for (let hour = 8; hour <= 19; hour++) {
//       let amPm = hour >= 12 ? "PM" : "AM";
//       let formattedHour = hour > 12 ? hour - 12 : hour;

//       // Check if the current time has passed for the current date and time slot
//       const isPast =
//         newDate.getDate() === currentDate.getDate() &&
//         (hour < currentHour || (hour === currentHour && currentMinutes >= 30));

//       result[dateString].push({
//         time: `${formattedHour}:00 ${amPm}`,
//         is_disabled: isWeekend || isPast ? 1 : 0,
//       });

//       if (hour !== 19) {
//         result[dateString].push({
//           time: `${formattedHour}:30 ${amPm}`,
//           is_disabled: isWeekend || isPast ? 1 : 0,
//         });
//       }
//     }
//   }

//   return result;
// }
const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

function generateDateJson(N) {
  const result = {};
  const currentDate = new Date(); // Get the current date
  const currentHour = currentDate.getHours();
  const currentMinutes = currentDate.getMinutes(); // Get the current minutes

  const currentTimestamp = new Date();
  for (let i = 0; i < N; i++) {
    const newDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() + i,
      currentTimestamp.getHours(), // Set current hour
      currentTimestamp.getMinutes()
    );
    const dateString = newDate.toISOString().split("T")[0]; // Format as YYYY-MM-DD
    //const isWeekend = newDate.getDay() === 0 || newDate.getDay() === 6;
    const isWeekend = newDate.getDay() === 0;
    result[dateString] = [];

    for (let hour = 8; hour <= 19; hour++) {
      let amPm = hour >= 12 ? "PM" : "AM";
      let formattedHour = hour > 12 ? hour - 12 : hour;

      // Check if the current time has passed for the current date and time slot
      const isPast =
        newDate.getDate() === currentDate.getDate() &&
        (hour < currentHour || (hour === currentHour && currentMinutes >= 30));

      const isToday = newDate.getDate() === currentDate.getDate();

      result[dateString].push({
        time: `${formattedHour}:00 ${amPm}`,
        is_disabled: isWeekend || (isToday && isPast) ? 1 : 0,
      });

      if (hour !== 19) {
        result[dateString].push({
          time: `${formattedHour}:30 ${amPm}`,
          is_disabled: isWeekend || (isToday && isPast) ? 1 : 0,
        });
      }
    }
  }

  return result;
}
const sections = [
  {
    title: "RENTAL DROPS",
    links: [
      {
        title: "Architecture Large Cities",
        href: "#",
      },
      {
        title: "Architecture Small Town",
        href: "#",
      },
    ],
  },
  {
    title: "CUSTOM DROPS",
    links: [
      {
        title: "Tips and tricks",
        href: "/tips-and-tricks",
      },
      {
        title: "Care and handling",
        href: "#",
      },
    ],
  },
  {
    title: "ACCOUNT",
    links: [
      {
        title: "Light box",
        href: "#",
      },
      {
        title: "Register",
        href: "#",
      },
      {
        title: "Reserve",
        href: "#",
      },
    ],
  },
  {
    title: "CUSTOM DROPS",
    links: [
      {
        title: "Rental Policies",
        href: "#",
      },
      {
        title: "Care & Handling",
        href: "#",
      },
      {
        title: "Image License Agreement",
        href: "#",
      },
    ],
  },
  {
    title: "SOCIAL MEDIA",
    links: [
      {
        title: "Facebook",
        href: "#",
      },
      {
        title: "Instagram",
        href: "#",
      },
      {
        title: "Twitter",
        href: "#",
      },
    ],
  },
];

function App() {
  const daysCount = 7;
  const scheduleJson = generateDateJson(daysCount);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDateTime, setSelectedDateTime] = useState(null); // State to store selected date and time
  const [successappoinment, setSuccessappoinment] = useState(false);
  const [showdate, setshowdate] = useState(null);
  const [loading1, setLoading1] = useState(false);
  const [color, setColor] = useState("#ffffff");
  const [localname, setlocalname] = useState(null);
  const [localemail, setlocalemail] = useState(null);

  const fireEvents = (eventData) => {
    TagManager.dataLayer({ dataLayer: eventData });
  };

  useEffect(() => {
    fireEvents({
      event: "appt_page_viewed", // Event name
      category: "button", // Event category
      action: "click", // Event action
    });
  }, []);

  const handleSlotSelect = (date, time) => {
    fireEvents({
      event: "appt_date_selected", // Event name
      category: "button", // Event category
      action: "click", // Event action
    });
    setSelectedDateTime({ date, time });

    const dateTimeString = date + " " + time;
    setshowdate(dateTimeString);

    const inputFormat = "YYYY-MM-DD hh:mm A"; // Adjust input format to match the date and time string
    const outputFormat = "YYYY-MM-DDTHH:mm:ss[Z]";

    const inputMoment = moment(dateTimeString, inputFormat);

    const outputDateString = inputMoment.format(outputFormat);
    setAppoimentdate(outputDateString);
    // Output: "2024-04-16T00:00:00Z"
  };
  const handleSlotDeselect = () => {
    // setSelectedDateTime(null);
    // setSelectedDate(null); // Clear selected date and time
  };

  const handleNextButtonClick = () => {
    fireEvents({
      event: "appt_schedule_btn_clicked", // Event name
      category: "button", // Event category
      action: "click", // Event action
    });
    setSelectedDate("selected");
    // Clear selected date and time
  };
  const handleNextButtonreselect = () => {
    setSelectedDateTime(null);
    setSelectedDate(null);
    // Clear selected date and time
  };

  // Create headers from the first item's date keys
  const headers = Object.keys(scheduleJson);
  const maxSlots = Math.max(
    ...headers.map((date) => scheduleJson[date].length)
  );

  const [loading, setLoading] = useState(false);
  const [emailvalidated, setemailvalidated] = useState(0);

  const [continuousLoading, setContinuousLoading] = useState(false);

  const [appoimentdate, setAppoimentdate] = useState(null); // State variable to track selected date
  const customStyle = {
    // Define your custom styles here
    // For example:
    backgroundColor: "lightblue",
    borderRadius: "5px",
    padding: "20px",
  };

  const today = new Date();
  let nextSlot = new Date(today.getTime() + 30 * 60000); // Adding 30 minutes in milliseconds

  // If the current minute is greater than or equal to 45 (15 minutes before the next hour), move to the next hour and set minutes to 0 for a 30-minute gap
  if (today.getMinutes() >= 45) {
    nextSlot = new Date(
      nextSlot.getFullYear(),
      nextSlot.getMonth(),
      nextSlot.getDate(),
      nextSlot.getHours() + 1,
      0,
      0
    );
  } else if (today.getMinutes() >= 30) {
    // If the current minute is greater than or equal to 30, move to the next hour and set minutes to 15 for a 15-minute gap
    nextSlot = new Date(
      nextSlot.getFullYear(),
      nextSlot.getMonth(),
      nextSlot.getDate(),
      nextSlot.getHours(),
      15,
      0
    );
  } else if (today.getMinutes() >= 15) {
    // If the current minute is greater than or equal to 15, set minutes to 30 for a 30-minute gap
    nextSlot.setMinutes(30);
  } else {
    // Otherwise, set the minutes to 15 for a 15-minute gap
    nextSlot.setMinutes(15);
  }

  nextSlot.setSeconds(0); // Set the seconds to 00

  // Check if the next slot exceeds 7 PM
  if (nextSlot.getHours() >= 19) {
    nextSlot = new Date(
      nextSlot.getFullYear(),
      nextSlot.getMonth(),
      nextSlot.getDate(),
      19,
      0,
      0
    );
  }

  // Log nextSlot only if it's before 7 PM
  if (nextSlot.getHours() < 19) {
    console.log(nextSlot);
  }

  // This will show the next available time slot, stopping at 7 PM
  // This will show the next available time slot with seconds set to 00

  const formattedDate2 = today.toLocaleDateString();
  const formattedDate1 = today.toLocaleDateString("en-US", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  console.log(formattedDate2, formattedDate1, "lo");
  const options = { month: "long", day: "numeric", year: "numeric" };
  const formattedDate = today.toLocaleDateString("en-US", options);

  const endDate = new Date();
  endDate.setDate(today.getDate() + 6);

  // Define other props
  // Define your days array
  const maxReservableAppointments = 3;

  function generateTimeSlots(startDate, endDate, intervalInSeconds, unitTime) {
    const timeSlots = [];
    let currentTime = new Date(startDate);

    // Set the initial time to the start date
    currentTime.setSeconds(0);
    currentTime.setMilliseconds(0);

    while (currentTime <= endDate) {
      const formattedTime = currentTime.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "2-digit",
      });

      // Check if the current time is after 7:00 PM
      if (
        currentTime.getHours() < 19 ||
        (currentTime.getHours() === 19 && currentTime.getMinutes() === 0)
      ) {
        timeSlots.push({
          time: formattedTime,
          unit: unitTime,
        });
      }

      // Increment by the specified interval in seconds
      currentTime.setSeconds(currentTime.getSeconds() + intervalInSeconds);
    }

    return timeSlots;
  }

  // Example usage:
  const startTime = new Date();
  startTime.setHours(8, 0); // Set start time to 8:00 AM
  const endTime = new Date();
  endTime.setHours(19, 0); // Set end time to 7:00 PM (7 PM is 19:00 in 24-hour format)

  const timeSlots = generateTimeSlots(startTime, endTime, 30 * 60, "seconds");
  console.log(timeSlots);

  const validateName = (inputName) => {
    const regex = /^[A-Za-z]{2,}(?: [A-Za-z]{2,})+$/;
    const invalidKeywords =
      /(test|sample|example|junk|sales|marketing|info|contact)/i;

    // Check if the name is valid and does not contain invalid keywords
    return regex.test(inputName) && !invalidKeywords.test(inputName);
  };

  const validateEmail = (inputEmail) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const freeEmailDomains =
      /(gmail\.com$|outlook\.com$|hotmail\.com$|yahoo\.com$|aol\.com$|mail\.com$|inbox\.com$|ymail\.com$|icloud\.com$|live\.com$|123\.com$|zoho\.com$|protonmail\.com$|gmx\.com$|fastmail\.com$|me\.com$|msn\.com$|bellsouth\.net$|telus\.net$|shaw\.ca$|cox\.net$|charter\.net$|earthlink\.net$|rediffmail\.com$|verizon\.net$|optonline\.net$|comcast\.net$|rr\.com$)/;

    const invalidKeywords =
      /^(test|sample|example|info|contact|admin|webadmin|sales|marketing|support)/i;

    return (
      emailRegex.test(inputEmail) &&
      !freeEmailDomains.test(inputEmail) &&
      !invalidKeywords.test(inputEmail.split("@")[0]) &&
      !/^job(s)?@/i.test(inputEmail)
    );
  };

  
  // const validateEmail = (inputEmail) => {
  //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  //   const freeEmailDomains =
  //     /(gmail\.com$|outlook\.com$|hotmail\.com$|yahoo\.com$|aol\.com$|mail\.com$|inbox\.com$|ymail\.com$|icloud\.com$|live\.com$|123\.com$|zoho\.com$|protonmail\.com$|gmx\.com$|fastmail\.com$|me\.com$|msn\.com$|bellsouth\.net$|telus\.net$|shaw\.ca$|cox\.net$|charter\.net$|earthlink\.net$|rediffmail\.com$|verizon\.net$|optonline\.net$|comcast\.net$|rr\.com$)/;

  //   const invalidKeywords =
  //     /^(test|sample|example|info|contact|admin|webadmin|sales|marketing|support)/i;

  //   return (
  //     emailRegex.test(inputEmail) &&
  //     !freeEmailDomains.test(inputEmail) &&
  //     !invalidKeywords.test(inputEmail.split("@")[0])
  //   );
  // };

  const schema = Yup.object({
    name: Yup.string()
      .test(
        "isValidName", // Name of the test
        "Name must be properly formatted and cannot include invalid keywords", // Error message
        (value) => validateName(value) // Test function that takes the value of the field
      )
      .required("Name is required"),
    email: Yup.string()
      .test(
        "isValidEmail", // Name of the test
        "Email must be properly formatted and cannot include invalid keywords", // Error message
        (value) => validateEmail(value) // Test function that takes the value of the field
      )
      .email("Invalid email address")
      .required("Email is required"),
    services: Yup.array()
      .min(1, "Please select at least one service")
      .required("Please select at least one service"),
  });

  const {
    reset,
    watch,
    control,
    trigger,
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      // gender: null,
      // marital_status: null,
      // image: null
    },
  });

  const storeinformation = async () => {
    console.log(localname, localemail, "emaillllllllllllllllllllllllllll");
    try {
      if (localemail) {
        const payloademail = {
          name: localname,
          email: localemail,
        };

        const responseemail = await fetch(
          "https://pirbx1pn53.execute-api.us-west-2.amazonaws.com/prod/storeContact",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payloademail),
          }
        );
      } else {
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (localemail) {
      storeinformation();
    }
  }, [localemail]);

  const onSubmit = async (data) => {
    setemailvalidated(0);
    setLoading1(true);

    const payloademail = {
      email: data.email,
    };

    const payload = {
      email: data.email,
      dateTime: appoimentdate,
      subject: data.services.join(", "),
      name: data.name,
    };

    try {
      const responseemail = await fetch(
        "https://pirbx1pn53.execute-api.us-west-2.amazonaws.com/prod/validateEMail",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payloademail),
        }
      );

      if (responseemail.ok) {
        const response = await fetch(
          "https://pirbx1pn53.execute-api.us-west-2.amazonaws.com/prod/appointment",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          }
        );

        if (response.ok) {
          fireEvents({
            event: "appt_submited_appoinment", // Event name
            category: "button", // Event category
            action: "click", // Event action
          });

          setSuccessappoinment(true);
          setLoading1(false);
          toast.success("Appointment booked successfully! ", {
            style: {
              border: "1px solid red",
              padding: "16px",
              color: "white",
            },
            iconTheme: {
              primary: "green",
              secondary: "#FFFAEE",
            },
            duration: 3000,
          });
        } else {
          setLoading1(false);
          toast.error("Failed to book appointment:", response.statusText, {
            style: {
              border: "1px solid red",
              padding: "16px",
              color: "white",
            },
            iconTheme: {
              primary: "green",
              secondary: "#FFFAEE",
            },
            duration: 3000,
          });
          console.error("Failed to book appointment:", response.statusText);
        }
      } else {
        setemailvalidated(2);
        setLoading1(false);
        toast.error(
          "Email could not be verified, Please enter a valid Email!",
          responseemail.statusText,
          {
            style: {
              border: "1px solid red",
              padding: "16px",
              color: "white",
            },
            iconTheme: {
              primary: "green",
              secondary: "#FFFAEE",
            },
            duration: 3000,
          }
        );
      }
    } catch (error) {
      setLoading1(false);
      toast.error("Failed to book appointment:", error, {
        style: {
          border: "1px solid red",
          padding: "16px",
          color: "white",
        },
        iconTheme: {
          primary: "green",
          secondary: "#FFFAEE",
        },
        duration: 3000,
      });
      console.error("Error:", error);
    }
  };

  return (
    <>
      <Container>
        <Row className=" py-2 justify-content-center">
          <Col sm={12}>
            <Card className="card_b">
              <div className="">
                <Row className="mb-2">
                  <Col xs={6} sm={2} className="align-self-center">
                    <div className="brd_right brd_right_mo">
                      <a
                        href="https://businesscompassllc.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Image
                          src={logii}
                          className="com_logo"
                          alt="Business Compass Logo"
                        />
                      </a>
                    </div>
                  </Col>
                  <Col xs={6} sm={3} className="align-self-center">
                    <div className="">
                      <a
                        href="https://partners.amazonaws.com/partners/0010L00001kY07PQAS/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Image
                          src={logiiaws}
                          className="aws_lo"
                          alt="Business Compass Logo"
                        />
                      </a>
                    </div>
                  </Col>
                  <Col sm={7} className="align-self-center mt-4 mt-sm-0">
                    <p className="aw_tx">
                      AWS Advanced Consulting Partner with extensive experience
                      including AWS Set Up, Migration, Security, Serverless,
                      Data Warehouse, Data Lake, Web, AI/ML, Generative AI, and
                      Media experience.
                    </p>
                  </Col>
                  {/* 
                  <Col sm={12} className="mt-1 nk">
                    <h4 className="mb-1" style={{ color: "#000" }}>
                      Business Compass® LLC
                    </h4>
                    <Image src={star} className="rat_st_im" alt="rating" />
                    <p className="rat_in_t mb-1">
                      4.9 out of 5 Star Rating by AWS Customers
                    </p>
                  </Col>
                  <Col sm={"auto"} className="nk">
                    <div className="media">
                      <MdOutlineAccessTime className="ico_so" />
                      <div className="media-body">
                        <h6 className="mt-0">30 min</h6>
                      </div>
                    </div>
                  </Col>
                  <Col sm={10} className="">
                    <div className="media">
                      <MdOutlineVideocam className="ico_so" />
                      <div className="media-body">
                        <h6 className="mt-0">
                          Web conferencing details provided upon confirmation.
                        </h6>
                      </div>
                    </div>
                  </Col> */}
                </Row>
              </div>

              <Row className="mb-2 align-items-center">
                <Col sm={5} className="mt-1">
                  <div className="nk">
                    <h4 className="mb-1" style={{ color: "#000" }}>
                      Business Compass® LLC
                    </h4>
                    <Image src={star} className="rat_st_im" alt="rating" />
                    <p className="rat_in_t mb-1">
                      4.9 out of 5 Star Rating by AWS Customers
                    </p>
                    <div className="media">
                      <MdOutlineAccessTime className="ico_so" />
                      <div className="media-body">
                        <h6 className="mt-0">30 min</h6>
                      </div>
                    </div>
                    <div className="media">
                      <MdOutlineVideocam className="ico_so" />
                      <div className="media-body">
                        <h6 className="mt-0">
                          Web conferencing details provided upon confirmation.
                        </h6>
                      </div>
                    </div>
                  </div>
                </Col>

                <Col sm={7} className="">
                  <div class="offer-container">
                    <div className="offer" style={{ width: "100%" }}>
                      30% OFF ON YOUR FIRST PROJECT <br />
                      FOR NEW CUSTOMERS ONLY!!!
                    </div>
                  </div>
                </Col>
              </Row>
              <div className="divid mt-0 p-3 pt-1">
                <Row className="mb-4">
                  {/* <Col sm={3} className="brd_right">
                    <div className="sticky">
                      <div className="">
                        <a
                          href="https://businesscompassllc.com/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Image
                            src={logii}
                            className="com_left_logo"
                            alt="Business Compass Logo"
                          />
                        </a>
                        

                      </div>
                    </div>
                  </Col> */}
                  {!successappoinment && (
                    <Col sm={12} className="mt-1">
                      <div className="ins">
                        Schedule a free appointment with Business Compass LLC
                      </div>
                      <div className="">
                        {!selectedDate && (
                          <div classname="mt-4">
                            <div class="">
                              <div class="table-responsive">
                                <table class="table table-bordered text-center">
                                  <thead>
                                    <tr class="bg-light-gray">
                                      {headers.map((header) => (
                                        <th key={header}>
                                          {moment(header).format("dddd")}
                                          <small
                                            style={{
                                              fontSize: "0.8em",
                                              display: "block",
                                              opacity: 0.8,
                                              fontWeight: 600,
                                            }}
                                          >
                                            {header}
                                          </small>
                                        </th>
                                      ))}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {Array.from({ length: maxSlots }).map(
                                      (_, slotIndex) => (
                                        <tr key={slotIndex}>
                                          {headers.map((date) => (
                                            <td
                                              key={date}
                                              style={{
                                                backgroundColor: scheduleJson[
                                                  date
                                                ][slotIndex].is_disabled
                                                  ? "#ebdddd"
                                                  : selectedDateTime &&
                                                    selectedDateTime.date ===
                                                      date &&
                                                    selectedDateTime.time ===
                                                      scheduleJson[date][
                                                        slotIndex
                                                      ].time
                                                  ? "initial"
                                                  : "initial",
                                                cursor: scheduleJson[date][
                                                  slotIndex
                                                ].is_disabled
                                                  ? "not-allowed"
                                                  : "pointer",
                                                opacity: scheduleJson[date][
                                                  slotIndex
                                                ].is_disabled
                                                  ? 0.2
                                                  : 1,
                                              }}
                                              onClick={() => {
                                                if (
                                                  !scheduleJson[date][slotIndex]
                                                    .is_disabled
                                                ) {
                                                  if (
                                                    selectedDateTime &&
                                                    selectedDateTime.date ===
                                                      date &&
                                                    selectedDateTime.time ===
                                                      scheduleJson[date][
                                                        slotIndex
                                                      ].time
                                                  ) {
                                                    handleSlotDeselect();
                                                  } else {
                                                    handleSlotSelect(
                                                      date,
                                                      scheduleJson[date][
                                                        slotIndex
                                                      ].time
                                                    );
                                                  }
                                                }
                                              }}
                                            >
                                              <div className=""></div>
                                              {selectedDateTime &&
                                              selectedDateTime.date === date &&
                                              selectedDateTime.time ===
                                                scheduleJson[date][slotIndex]
                                                  .time ? (
                                                <button
                                                  className="btn_next"
                                                  onClick={
                                                    handleNextButtonClick
                                                  }
                                                >
                                                  Schedule
                                                </button>
                                              ) : (
                                                scheduleJson[date][
                                                  slotIndex
                                                ] && (
                                                  <button className="btn_time">
                                                    {
                                                      scheduleJson[date][
                                                        slotIndex
                                                      ].time
                                                    }
                                                  </button>
                                                )
                                              )}
                                            </td>
                                          ))}
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            {/* <AppointmentPicker
                        initialDay={nextSlot}
                        endDate={endDate} // Set the initial day to today
                        days={days}
                        maxReservableAppointments={maxReservableAppointments}
                        // alpha
                        visible
                        local="en-US"
                        unitTime={30 * 60 * 1000}
                        // selectedByDefault
                        loading={loading}
                        // continuous
                        className="appointment-picker"
                      /> */}
                          </div>
                        )}

                        {selectedDate && (
                          <>
                            <div className="container1">
                              <div
                                className="back1"
                                onClick={handleNextButtonreselect}
                              >
                                <MdOutlineArrowBackIos className="ico_so1" />
                                <span className="text1">Back</span>
                              </div>
                              <span className="sleft1">{showdate}</span>
                            </div>

                            <div className="mt-3">
                              <p className="on_on"></p>
                              <form onSubmit={handleSubmit(onSubmit)}>
                                <Row className="mt-4">
                                  <Col sm={7} className="">
                                    <div className="mb-3">
                                      <label
                                        htmlFor="name"
                                        className="form-label"
                                      >
                                        Name*
                                      </label>
                                      <Controller
                                        name="name"
                                        control={control}
                                        defaultValue=""
                                        rules={{ required: "Name is required" }}
                                        render={({ field }) => (
                                          <input
                                            type="text"
                                            className={`form-control ${
                                              errors.name ? "is-invalid" : ""
                                            }`}
                                            onBlurCapture={(e) => {
                                              setlocalname(e.target.value);
                                              fireEvents({
                                                event: "appt_name_entered", // Event name
                                                category: "button", // Event category
                                                action: "click", // Event action
                                              });
                                            }}
                                            onChange={() => {
                                              fireEvents({
                                                event: "appt_name_entered", // Event name
                                                category: "button", // Event category
                                                action: "click", // Event action
                                              });
                                            }}
                                            id="name"
                                            {...field}
                                            autoComplete="name"
                                          />
                                        )}
                                      />
                                      {errors.name && (
                                        <div className="invalid-feedback">
                                          {errors.name.message}
                                        </div>
                                      )}
                                    </div>
                                    <div className="mb-3">
                                      <label
                                        htmlFor="email"
                                        className="form-label"
                                      >
                                        Email*
                                      </label>
                                      <Controller
                                        name="email"
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                          required: "Email is required",
                                        }}
                                        render={({ field }) => (
                                          <input
                                            type="email"
                                            className={`form-control ${
                                              errors.email ? "is-invalid" : ""
                                            }`}
                                            onBlurCapture={(e) => {
                                              console.log(
                                                e.target.value,
                                                "emailssssssssssssss"
                                              );
                                              // storeinformation({
                                              //   email: e.target.value,
                                              // });
                                              setlocalemail(e.target.value);
                                              setemailvalidated(0);
                                              fireEvents({
                                                event: "appt_email_entered", // Event name
                                                category: "button", // Event category
                                                action: "click", // Event action
                                              });
                                            }}
                                            onChange={(e) => {
                                              field.onChange(e); // Make sure to call onChange of the field
                                              fireEvents({
                                                event: "appt_email_entered", // Event name
                                                category: "button", // Event category
                                                action: "click", // Event action
                                              });
                                              // Update emailvalidated state
                                            }}
                                            id="email"
                                            {...field}
                                            autoComplete="email"
                                          />
                                        )}
                                      />

                                      {/* <Controller
                                      name="email"
                                      control={control}
                                      defaultValue=""
                                      rules={{ required: "Email is required" }}
                                      render={({ field }) => (
                                        <input
                                          type="email"
                                          className={`form-control ${
                                            errors.email ? "is-invalid" : ""
                                          }`}
                                          onChange={() => {
                                            fireEvents({
                                              event: 'appt_email_entered', // Event name
                                              category: 'button', // Event category
                                              action: 'click', // Event action
                                            })
                                           
                                            setemailvalidated(0)
                                          }}
                                          
                                          id="email"
                                          {...field}
                                        />
                                      )}
                                    /> */}
                                      {errors.email && (
                                        <div className="invalid-feedback">
                                          {errors.email.message}
                                        </div>
                                      )}

                                      {emailvalidated == 2 && (
                                        <p className="nscolor">
                                          Please enter a valid email
                                        </p>
                                      )}
                                    </div>
                                  </Col>
                                </Row>
                                <p className="on_on mt-4">
                                  What you are looking for?
                                </p>
                                <div className="h_don_form">
                                  <div classname="checkbox-container">
                                    <Controller
                                      name="services"
                                      control={control}
                                      defaultValue={[]} // Default value is an empty array
                                      rules={{
                                        required:
                                          "Please select at least one service",
                                      }}
                                      render={({ field }) => (
                                        <>
                                          <p>
                                            <input
                                              type="checkbox"
                                              id="test1"
                                              name="radio-group"
                                              value="AWS Migration"
                                              checked={field.value.includes(
                                                "AWS Migration"
                                              )} // Check if the value is included in the field value array
                                              onChange={(e) => {
                                                fireEvents({
                                                  event:
                                                    "appt_subject_selected", // Event name
                                                  category: "button", // Event category
                                                  action: "click", // Event action
                                                });
                                                const newValue = e.target
                                                  .checked
                                                  ? [
                                                      ...field.value,
                                                      e.target.value,
                                                    ] // Add the value if checked
                                                  : field.value.filter(
                                                      (item) =>
                                                        item !== e.target.value
                                                    ); // Remove the value if unchecked
                                                field.onChange(newValue); // Update the field value
                                              }}
                                            />
                                            <label htmlFor="test1">
                                              AWS Migration
                                            </label>
                                          </p>
                                          <p>
                                            <input
                                              type="checkbox"
                                              id="test2"
                                              name="radio-group"
                                              value="AWS Set Up"
                                              checked={field.value.includes(
                                                "AWS Set Up"
                                              )}
                                              onChange={(e) => {
                                                fireEvents({
                                                  event:
                                                    "appt_subject_selected", // Event name
                                                  category: "button", // Event category
                                                  action: "click", // Event action
                                                });
                                                const newValue = e.target
                                                  .checked
                                                  ? [
                                                      ...field.value,
                                                      e.target.value,
                                                    ]
                                                  : field.value.filter(
                                                      (item) =>
                                                        item !== e.target.value
                                                    );
                                                field.onChange(newValue);
                                              }}
                                            />
                                            <label htmlFor="test2">
                                              AWS Set Up
                                            </label>
                                          </p>
                                          <p>
                                            <input
                                              type="checkbox"
                                              id="test3"
                                              name="radio-group"
                                              value="AI/ML"
                                              checked={field.value.includes(
                                                "AI/ML"
                                              )}
                                              onChange={(e) => {
                                                fireEvents({
                                                  event:
                                                    "appt_subject_selected", // Event name
                                                  category: "button", // Event category
                                                  action: "click", // Event action
                                                });
                                                const newValue = e.target
                                                  .checked
                                                  ? [
                                                      ...field.value,
                                                      e.target.value,
                                                    ]
                                                  : field.value.filter(
                                                      (item) =>
                                                        item !== e.target.value
                                                    );
                                                field.onChange(newValue);
                                              }}
                                            />
                                            <label htmlFor="test3">AI/ML</label>
                                          </p>

                                          <p>
                                            <input
                                              type="checkbox"
                                              id="test7"
                                              name="radio-group"
                                              value="Media"
                                              checked={field.value.includes(
                                                "Media"
                                              )}
                                              onChange={(e) => {
                                                fireEvents({
                                                  event:
                                                    "appt_subject_selected", // Event name
                                                  category: "button", // Event category
                                                  action: "click", // Event action
                                                });
                                                const newValue = e.target
                                                  .checked
                                                  ? [
                                                      ...field.value,
                                                      e.target.value,
                                                    ]
                                                  : field.value.filter(
                                                      (item) =>
                                                        item !== e.target.value
                                                    );
                                                field.onChange(newValue);
                                              }}
                                            />
                                            <label htmlFor="test7">Media</label>
                                          </p>
                                          <p>
                                            <input
                                              type="checkbox"
                                              id="test9"
                                              name="radio-group"
                                              value="Security"
                                              checked={field.value.includes(
                                                "Security"
                                              )}
                                              onChange={(e) => {
                                                fireEvents({
                                                  event:
                                                    "appt_subject_selected", // Event name
                                                  category: "button", // Event category
                                                  action: "click", // Event action
                                                });
                                                const newValue = e.target
                                                  .checked
                                                  ? [
                                                      ...field.value,
                                                      e.target.value,
                                                    ]
                                                  : field.value.filter(
                                                      (item) =>
                                                        item !== e.target.value
                                                    );
                                                field.onChange(newValue);
                                              }}
                                            />
                                            <label htmlFor="test9">
                                              Security
                                            </label>
                                          </p>
                                          <p>
                                            <input
                                              type="checkbox"
                                              id="test8"
                                              name="radio-group"
                                              value="Mobile App"
                                              checked={field.value.includes(
                                                "Mobile App"
                                              )}
                                              onChange={(e) => {
                                                fireEvents({
                                                  event:
                                                    "appt_subject_selected", // Event name
                                                  category: "button", // Event category
                                                  action: "click", // Event action
                                                });
                                                const newValue = e.target
                                                  .checked
                                                  ? [
                                                      ...field.value,
                                                      e.target.value,
                                                    ]
                                                  : field.value.filter(
                                                      (item) =>
                                                        item !== e.target.value
                                                    );
                                                field.onChange(newValue);
                                              }}
                                            />
                                            <label htmlFor="test8">
                                              Mobile App
                                            </label>
                                          </p>
                                          <p>
                                            <input
                                              type="checkbox"
                                              id="test6"
                                              name="radio-group"
                                              value="Document Processing"
                                              checked={field.value.includes(
                                                "Document Processing"
                                              )}
                                              onChange={(e) => {
                                                fireEvents({
                                                  event:
                                                    "appt_subject_selected", // Event name
                                                  category: "button", // Event category
                                                  action: "click", // Event action
                                                });
                                                const newValue = e.target
                                                  .checked
                                                  ? [
                                                      ...field.value,
                                                      e.target.value,
                                                    ]
                                                  : field.value.filter(
                                                      (item) =>
                                                        item !== e.target.value
                                                    );
                                                field.onChange(newValue);
                                              }}
                                            />
                                            <label htmlFor="test6">
                                              Document Processing
                                            </label>
                                          </p>
                                          <p>
                                            <input
                                              type="checkbox"
                                              id="test10"
                                              name="radio-group"
                                              value="Web"
                                              checked={field.value.includes(
                                                "Web"
                                              )}
                                              onChange={(e) => {
                                                fireEvents({
                                                  event:
                                                    "appt_subject_selected", // Event name
                                                  category: "button", // Event category
                                                  action: "click", // Event action
                                                });

                                                const newValue = e.target
                                                  .checked
                                                  ? [
                                                      ...field.value,
                                                      e.target.value,
                                                    ]
                                                  : field.value.filter(
                                                      (item) =>
                                                        item !== e.target.value
                                                    );
                                                field.onChange(newValue);
                                              }}
                                            />
                                            <label htmlFor="test10">Web</label>
                                          </p>
                                          <p>
                                            <input
                                              type="checkbox"
                                              id="test12"
                                              name="radio-group"
                                              value="Data Warehouse"
                                              checked={field.value.includes(
                                                "Data Warehouse"
                                              )}
                                              onChange={(e) => {
                                                fireEvents({
                                                  event:
                                                    "appt_subject_selected", // Event name
                                                  category: "button", // Event category
                                                  action: "click", // Event action
                                                });
                                                const newValue = e.target
                                                  .checked
                                                  ? [
                                                      ...field.value,
                                                      e.target.value,
                                                    ]
                                                  : field.value.filter(
                                                      (item) =>
                                                        item !== e.target.value
                                                    );
                                                field.onChange(newValue);
                                              }}
                                            />
                                            <label htmlFor="test12">
                                              Data Warehouse
                                            </label>
                                          </p>
                                          <p>
                                            <input
                                              type="checkbox"
                                              id="test13"
                                              name="radio-group"
                                              value="Data Lake"
                                              checked={field.value.includes(
                                                "Data Lake"
                                              )}
                                              onChange={(e) => {
                                                fireEvents({
                                                  event:
                                                    "appt_subject_selected", // Event name
                                                  category: "button", // Event category
                                                  action: "click", // Event action
                                                });
                                                const newValue = e.target
                                                  .checked
                                                  ? [
                                                      ...field.value,
                                                      e.target.value,
                                                    ]
                                                  : field.value.filter(
                                                      (item) =>
                                                        item !== e.target.value
                                                    );
                                                field.onChange(newValue);
                                              }}
                                            />
                                            <label htmlFor="test13">
                                              Data Lake
                                            </label>
                                          </p>
                                          <p>
                                            <input
                                              type="checkbox"
                                              id="test14"
                                              name="radio-group"
                                              value="Remote Work Setup"
                                              checked={field.value.includes(
                                                "Remote Work Setup"
                                              )}
                                              onChange={(e) => {
                                                fireEvents({
                                                  event:
                                                    "appt_subject_selected", // Event name
                                                  category: "button", // Event category
                                                  action: "click", // Event action
                                                });
                                                const newValue = e.target
                                                  .checked
                                                  ? [
                                                      ...field.value,
                                                      e.target.value,
                                                    ]
                                                  : field.value.filter(
                                                      (item) =>
                                                        item !== e.target.value
                                                    );
                                                field.onChange(newValue);
                                              }}
                                            />
                                            <label htmlFor="test14">
                                              Remote Work Setup
                                            </label>
                                          </p>
                                          <p>
                                            <input
                                              type="checkbox"
                                              id="test34"
                                              name="radio-group"
                                              value="Analytics"
                                              checked={field.value.includes(
                                                "Analytics"
                                              )}
                                              onChange={(e) => {
                                                fireEvents({
                                                  event:
                                                    "appt_subject_selected", // Event name
                                                  category: "button", // Event category
                                                  action: "click", // Event action
                                                });
                                                const newValue = e.target
                                                  .checked
                                                  ? [
                                                      ...field.value,
                                                      e.target.value,
                                                    ]
                                                  : field.value.filter(
                                                      (item) =>
                                                        item !== e.target.value
                                                    );
                                                field.onChange(newValue);
                                              }}
                                            />
                                            <label htmlFor="test34">
                                              Analytics
                                            </label>
                                          </p>
                                          <p>
                                            <input
                                              type="checkbox"
                                              id="test35"
                                              name="radio-group"
                                              value="Serverless"
                                              checked={field.value.includes(
                                                "Serverless"
                                              )}
                                              onChange={(e) => {
                                                fireEvents({
                                                  event:
                                                    "appt_subject_selected", // Event name
                                                  category: "button", // Event category
                                                  action: "click", // Event action
                                                });
                                                const newValue = e.target
                                                  .checked
                                                  ? [
                                                      ...field.value,
                                                      e.target.value,
                                                    ]
                                                  : field.value.filter(
                                                      (item) =>
                                                        item !== e.target.value
                                                    );
                                                field.onChange(newValue);
                                              }}
                                            />
                                            <label htmlFor="test35">
                                              Serverless
                                            </label>
                                          </p>
                                          <p>
                                            <input
                                              type="checkbox"
                                              id="test36"
                                              name="radio-group"
                                              value="Generative AI"
                                              checked={field.value.includes(
                                                "Generative AI"
                                              )}
                                              onChange={(e) => {
                                                fireEvents({
                                                  event:
                                                    "appt_subject_selected", // Event name
                                                  category: "button", // Event category
                                                  action: "click", // Event action
                                                });
                                                const newValue = e.target
                                                  .checked
                                                  ? [
                                                      ...field.value,
                                                      e.target.value,
                                                    ]
                                                  : field.value.filter(
                                                      (item) =>
                                                        item !== e.target.value
                                                    );
                                                field.onChange(newValue);
                                              }}
                                            />
                                            <label htmlFor="test36">
                                              Generative AI
                                            </label>
                                          </p>
                                          <p>
                                            <input
                                              type="checkbox"
                                              id="test37"
                                              name="radio-group"
                                              value="Custom Calendar"
                                              checked={field.value.includes(
                                                "Custom Calendar"
                                              )}
                                              onChange={(e) => {
                                                fireEvents({
                                                  event:
                                                    "appt_subject_selected", // Event name
                                                  category: "button", // Event category
                                                  action: "click", // Event action
                                                });
                                                const newValue = e.target
                                                  .checked
                                                  ? [
                                                      ...field.value,
                                                      e.target.value,
                                                    ]
                                                  : field.value.filter(
                                                      (item) =>
                                                        item !== e.target.value
                                                    );
                                                field.onChange(newValue);
                                              }}
                                            />
                                            <label htmlFor="test37">
                                              Custom Calendar
                                            </label>
                                          </p>
                                          <p>
                                            <input
                                              type="checkbox"
                                              id="test67"
                                              name="radio-group"
                                              value="WordPress Upgrade"
                                              checked={field.value.includes(
                                                "WordPress Upgrade"
                                              )}
                                              onChange={(e) => {
                                                fireEvents({
                                                  event:
                                                    "appt_subject_selected", // Event name
                                                  category: "button", // Event category
                                                  action: "click", // Event action
                                                });
                                                const newValue = e.target
                                                  .checked
                                                  ? [
                                                      ...field.value,
                                                      e.target.value,
                                                    ]
                                                  : field.value.filter(
                                                      (item) =>
                                                        item !== e.target.value
                                                    );
                                                field.onChange(newValue);
                                              }}
                                            />
                                            <label htmlFor="test67">
                                              WordPress Upgrade
                                            </label>
                                          </p>
                                          <p>
                                            <input
                                              type="checkbox"
                                              id="test11"
                                              name="radio-group"
                                              value="Other"
                                              checked={field.value.includes(
                                                "Other"
                                              )}
                                              onChange={(e) => {
                                                fireEvents({
                                                  event:
                                                    "appt_subject_selected", // Event name
                                                  category: "button", // Event category
                                                  action: "click", // Event action
                                                });
                                                const newValue = e.target
                                                  .checked
                                                  ? [
                                                      ...field.value,
                                                      e.target.value,
                                                    ]
                                                  : field.value.filter(
                                                      (item) =>
                                                        item !== e.target.value
                                                    );
                                                field.onChange(newValue);
                                              }}
                                            />
                                            <label htmlFor="test11">
                                              Others
                                            </label>
                                          </p>
                                        </>
                                      )}
                                    />
                                  </div>

                                  <p className="Errorservice">
                                    {" "}
                                    {errors?.services?.message}
                                  </p>
                                </div>
                                {/* {getValues("services") == "Other" && (
                                  <>
                                    <div className="mb-3">
                                      <label
                                        htmlFor="name"
                                        className="form-label"
                                      >
                                        Name*
                                      </label>
                                      <Controller
                                        name="name"
                                        control={control}
                                        defaultValue=""
                                        rules={{ required: "Name is required" }}
                                        render={({ field }) => (
                                          <input
                                            type="text"
                                            className={`form-control ${
                                              errors.name ? "is-invalid" : ""
                                            }`}
                                            onBlurCapture={() => {
                                              fireEvents({
                                                event: "appt_name_entered", // Event name
                                                category: "button", // Event category
                                                action: "click", // Event action
                                              });
                                            }}
                                            onChange={() => {
                                              fireEvents({
                                                event: "appt_name_entered", // Event name
                                                category: "button", // Event category
                                                action: "click", // Event action
                                              });
                                            }}
                                            id="name"
                                            {...field}
                                            autoComplete="name"
                                          />
                                        )}
                                      />
                                      {errors.name && (
                                        <div className="invalid-feedback">
                                          {errors.name.message}
                                        </div>
                                      )}
                                    </div>
                                  </>
                                )} */}
                                {/* <p className="note_en">
                          By proceeding, you confirm that you have read and agree
                          to{" "}
                          <a
                            target="_blank"
                            href="https://businesscompassllc.com/terms-of-service/"
                          >
                            Terms of Use
                          </a>{" "}
                          and{" "}
                          <a
                            target="_blank"
                            href="https://businesscompassllc.com/privacy-policy/"
                          >
                            Privacy Policy.
                          </a>
                        </p> */}
                                {/* <p>{{JSON.stringfy (getValues("subject")}</p> */}
                                {/* {JSON.stringify(getValues("services"))} */}
                                <button
                                  type="submit"
                                  className="btn btn-primary mb-4"
                                  disabled={loading1}
                                  onClick={() => {
                                    fireEvents({
                                      event: "appt_submit_button_clicked", // Event name
                                      category: "button", // Event category
                                      action: "click", // Event action
                                    });
                                  }}
                                >
                                  {loading1 ? (
                                    <BeatLoader
                                      color={color}
                                      loading={loading1}
                                      cssOverride={override}
                                      size={15}
                                      aria-label="Loading Spinner"
                                      data-testid="loader"
                                    />
                                  ) : (
                                    "Submit"
                                  )}
                                </button>
                              </form>
                            </div>
                          </>
                        )}
                      </div>
                    </Col>
                  )}
                  {successappoinment && (
                    <Col sm={12} className="my-4">
                      <link
                        href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
                        rel="stylesheet"
                      />
                      <section class="mail-seccess section">
                        <div class="container">
                          <div class="row justify-content-center">
                            <div class="col-lg-8">
                              <div class="success-inner">
                                <h1>
                                  <i class="fa fa-envelope"></i>
                                  <span>Your calendar invite on its way!</span>
                                </h1>
                                <p>
                                  Your appointment has been successfully
                                  scheduled. Please check your email for
                                  confirmation details and further instructions.
                                  Thank you for choosing us!
                                </p>
                                <a
                                  class="btn btn-primary btn-lg"
                                  href="https://businesscompassllc.com/"
                                >
                                  Learn more about Business Compass LLC
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </Col>
                  )}
                </Row>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
      <footer class="site-footer">
        <div class="container">
          <div class="row">
            <div class="col-lg-3 col-md-6">
              <section class="widget widget-light-skin">
                <h1 class="widget-title">IMPORTANT LINKS</h1>
                <ul class="gg">
                  <li>
                    <a
                      target="blank"
                      href="https://businesscompassllc.com/case-studies/"
                    >
                      Case Studies
                    </a>
                  </li>
                  <li>
                    <a
                      target="blank"
                      href="https://businesscompassllc.com/robotics/"
                    >
                      Robotics
                    </a>
                  </li>
                  <li>
                    <a
                      target="blank"
                      href="https://businesscompassllc.com/publications/"
                    >
                      Publications
                    </a>
                  </li>
                  <li>
                    <a
                      target="blank"
                      href="https://businesscompassllc.com/privacy-policy/"
                    >
                      Privacy Policy
                    </a>
                  </li>
                  <li>
                    <a
                      target="blank"
                      href="https://businesscompassllc.com/terms-of-service/"
                    >
                      Terms Of Service
                    </a>
                  </li>
                  <li>
                    <a
                      target="blank"
                      href="https://businesscompassllc.com/trademarks/"
                    >
                      Trademarks
                    </a>
                  </li>
                  <li>
                    <a
                      target="blank"
                      href="https://businesscompassllc.com/partners/"
                    >
                      Partners
                    </a>
                  </li>
                  <li>
                    <a
                      target="blank"
                      href="https://businesscompassllc.com/press/"
                    >
                      Press
                    </a>
                  </li>
                  {/* <li><a target="blank" href="https://businesscompassllc.com/contact-us/">Contact Us</a></li> */}
                </ul>
              </section>
            </div>
            <div class="col-lg-3 col-md-6">
              <section class="widget widget-links widget-light-skin">
                <h3 class="widget-title">AWS ADVANCED CONSULTING PARTNER</h3>
                <div className="">
                  <a
                    href="https://partners.amazonaws.com/partners/0010L00001kY07PQAS/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Image
                      src={logiiaws}
                      className="aws_lo1"
                      alt="Business Compass Logo"
                    />
                  </a>
                </div>
              </section>
            </div>
            <div class="col-lg-3 col-md-6">
              <section class="widget widget-light-skin">
                <h3 class="widget-title">CHANNELS</h3>
                <div className="img_foo">
                  <a
                    href="https://www.amazon.com/dp/B004DM1ZTQ"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Image
                      src={amazonappstore}
                      className="footer_lo"
                      alt="Business Compass Logo1"
                    />
                  </a>
                  <a
                    href="https://play.google.com/store/search?q=business+compass&c=apps"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Image
                      src={appstorebadge}
                      className="footer_lo"
                      alt="Business Compass Logo2"
                    />
                  </a>
                  <a
                    // href="https://play.google.com/store/search?q=business+compass&pli=1&c=apps"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Image
                      src={googleplaybadge}
                      className="footer_lo"
                      alt="Business Compass Logo3"
                    />
                  </a>
                </div>
              </section>
            </div>

            <div class="col-lg-3 col-md-6">
              <section class="widget widget-links widget-light-skin">
                <h3 class="widget-title">CONTACT US</h3>
                <ul class="gk">
                  <li>PO Box 564</li>
                  <li>Mt. Freedom</li>
                  <li>NJ 07970</li>
                  <li>USA</li>
                  <li>
                    Phone: <a href="tel:+1 (973) 944-3989">+1 (973) 944-3989</a>
                  </li>
                  <li>
                    Web:{" "}
                    <a href="https://businesscompassllc.com/">
                      businesscompassllc.com
                    </a>
                  </li>
                </ul>
              </section>
            </div>
          </div>
          {/* <hr class="hr-light mt-2 margin-bottom-2x"> */}

          <p class="footer-copyright">
            <a>&copy; Copyright Business Compass LLC</a>
          </p>
          <p class="footer-copyright1">
            <a>
              {" "}
              Disclaimer: No claim is made to the exclusive right to use
              "BUSINESS", apart from the mark as shown.
            </a>
          </p>
        </div>
      </footer>
    </>
  );
}

export default App;
